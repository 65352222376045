<template>
  <div class="notification-drop" :class="[localDisplayLang === 'eng' ? 'default' : 'default']" id="notify-bell">
    <div id="notification-drop-parent" class="drop-header">
      <h3 class="gray-text notification-drop-parent">{{ $t("Notifications") }}</h3>
      <p
        v-if="notificationArray && notificationArray.length > 0"
        @click="clearAllNotifications"
        class="gray-text clear-all"
      >
        {{ $t("Clear all") }}
      </p>
    </div>
    <div class="body-container">
      <div v-if="notificationArray && notificationArray.length > 0">
        <div
          class="drop-body"
          v-for="(data, index) in notificationArray"
          :key="index"
          @click="notificationAction(data)"
        >
          <div class="left-side-notify">
            <div v-if="data.imageURL" class="notification-image">
              <img class="image-card" :src="data.imageURL" alt="test" />
            </div>

            <div class="notification-body">
              <h4 class="notify-title" :class="[data.isRead ? 'gray-text' : 'white-text']">{{ data.title }}</h4>
              <p class="notify-desc" :class="[data.isRead ? 'gray-text' : 'white-text']">{{ data.message }}</p>
            </div>
          </div>

          <div class="cta" v-if="data.cta && data.deeplink">
            <!-- <button  class="cta-button">{{ data.cta }}</button> -->
            <div class="cta-button">{{ $t(data.cta) }}</div>
          </div>
        </div>
      </div>

      <div v-if="!notificationArray">
        <div class="no-notifications">
          <h4 class="gray-text">{{ $t("No Notifications Found") }}</h4>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { eventBus } from "@/eventBus";

import { mapGetters } from "vuex";

export default {
  data() {
    return {
      notificationArray: [],
      localDisplayLang: null,
      deviceId: null,
    };
  },

  mounted() {
    this.localDisplayLang = localStorage.getItem("setDisplayLanguageCode");

    this.deviceId = localStorage.getItem("random");

    this.getNotificationsFromLocalStorage();

    
    eventBus.$on("check-local-storage", (data) => {
      this.localDisplayLang = data;
    });
  },

  computed: {
    ...mapGetters(["subscriberid"]),
  },

  methods: {
    showDetails() {
      eventBus.$emit("show-notification-details", true);
    },

    clearAllNotifications() {
      let notificationFromStorage = JSON.parse(localStorage.getItem("notifications"));

      if (notificationFromStorage) {
        // Check if logged in user
        if (this.subscriberid) {
          delete notificationFromStorage[this.subscriberid];
        }
        // If guest user
        else if (this.deviceId) {
          delete notificationFromStorage[this.deviceId];
        }

        

        localStorage.setItem("notifications", JSON.stringify(notificationFromStorage));
        eventBus.$emit("close-notification-drop", true);
      }

      // localStorage.removeItem("notifications");
    },

    notificationAction(data) {
      
     
      let notifications = JSON.parse(localStorage.getItem("notifications"));

      const deviceId = localStorage.getItem("random");

      let notificationsArr;

      let index;

      if (this.subscriberid) {
        notificationsArr = notifications[this.subscriberid];
      } else if (deviceId) {
        notificationsArr = notifications[deviceId];
      }

      if (!data.isRead) {
        notificationsArr.forEach((el, i) => {
          if (!el.isRead && el.updatedAt === data.updatedAt) {
            index = i;
            
          }
        });

        notificationsArr[index] = { ...data, isRead: true };

        

        if (this.subscriberid) {
          notifications[this.subscriberid] = notificationsArr;
        } else if (deviceId) {
          notifications[deviceId] = notificationsArr;
        }

        localStorage.setItem("notifications", JSON.stringify(notifications));
      }

      if (data.isDirectDeeplink && data.deeplink) {
        window.open(data.deeplink, "_self");
      }

      // Click redirection code - Watch Now
      else if (data.cta == "WATCH NOW" && data.contentId) {
        this.$router.push(`viewContent/${data.contentId}`);
      }

      // Click redirection code - ViewTicket
      else if (data.cta == "VIEW TICKET") {
        const enitreURL = data.deeplink;
        const domain = window.location.host;
        const justParams = enitreURL.split(domain);
        this.$router.push(justParams[1]);
      } else if (data.isDirectDeeplink && data.deeplink) {
      }

      eventBus.$emit("getNotificationsHeaderCount");
    },

    getNotificationsFromLocalStorage() {
      

      const localNotifications = JSON.parse(localStorage.getItem("notifications"));

      // let deviceId = localStorage.getItem("random");

      let currentUserNotifications;

      if (localNotifications && Object.keys(localNotifications).length > 0) {
        if (this.subscriberid) {
          

          if (localNotifications[this.deviceId]) {
            currentUserNotifications = [...localNotifications[this.subscriberid], ...localNotifications[this.deviceId]];
            
          } else {
            currentUserNotifications = localNotifications[this.subscriberid];
          }

          // currentUserNotifications = localNotifications[this.subscriberid];
        } else if (localNotifications[this.deviceId]) {
          currentUserNotifications = localNotifications[this.deviceId];
        }

        if (currentUserNotifications && currentUserNotifications.length > 0) {
          currentUserNotifications.sort((a, b) => {
            return b.recievedDate - a.recievedDate;
          });

          this.notificationArray = currentUserNotifications;
        } else {
          this.notificationArray = null;
        }
      } else {
        this.notificationArray = null;
      }

      
    },
  },

  beforeDestroy() {
    eventBus.$off("check-local-storage");
  },
};
</script>

<style lang="scss">
@import "@/sass/_variables.scss";
@import "./notification.scss"
</style>
